import { store } from "../store";
import { ISecondLevel as ISecondLevelFunction } from "../store/reducers/functions";
import { IMatrixEntry } from "../store/reducers/matrix";

function findCategory(theObject: any, id: string): any {
    var result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject.length; i++) {
            result = findCategory(theObject[i], id);
            if (result) {
                break;
            }
        }
    }
    else {
        for (var prop in theObject) {
            if (prop === 'node_id') {
                if (theObject[prop] === id) {
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = findCategory(theObject[prop], id);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

export interface IFunctionWithPoints extends ISecondLevelFunction {
    points: number;
    connectedCategories: any[]
}

class MatrixService {
    public calcFunctionValues() {
        const categories = this._getSelectedCategories();
        const matchingMatrixEntries = this._getMatchingMatrixEntries(categories);
        const functionsWithPoints = this._getFunctionsWithPoints(matchingMatrixEntries);

        // console.log("categories", categories);
        // console.log("matchingMatrixEntries", matchingMatrixEntries)
        // console.log("functionsWithPoints", functionsWithPoints)

        return functionsWithPoints.sort((a, b) => b.points - a.points).slice(0, 8);
    }

    public getSelectedCategoriesAsArray() {
        const categories = store.getState().categories;
        const categoryList: any[] = [];

        categories.categories.forEach(cat => {
            cat.nodes.forEach(firstLevel => {
                firstLevel.nodes.forEach(secondLevel => {
                    secondLevel.nodes.forEach(thirdLevel => {
                        if (thirdLevel.active) {
                            categoryList.push({
                                category: cat.name,
                                level1: firstLevel.name,
                                level2: secondLevel.name,
                                level3: thirdLevel.name
                            })
                        }
                    })
                })
            })
        })

        return categoryList;
    }

    private _getSelectedCategories() {
        const categories = store.getState().categories;
        const categoryList: string[] = [];

        categories.categories.forEach(cat => {
            cat.nodes.forEach(firstLevel => {
                let activeNodes = firstLevel.nodes.filter(secondLevel => {
                    let activeChilds = secondLevel.nodes.filter(thirdLevel => thirdLevel.active);

                    return activeChilds.length > 0;
                })

                activeNodes.forEach(x => categoryList.push(x.node_id))
            })
        })

        return categoryList;
    }

    private _getMatchingMatrixEntries(selectedCategoriesIDs: string[]) {
        const matrix = store.getState().matrix;

        return matrix.matrix.filter(matrixEntry => {
            const hasCategory = selectedCategoriesIDs.find(x => x === matrixEntry.category_id);

            return hasCategory !== undefined;
        })
    }

    private _getFunctionsWithPoints(matrixEntries: IMatrixEntry[]) {
        const functions = store.getState().functions;
        const functionsWithPoints: IFunctionWithPoints[] = [];

        const functionList: ISecondLevelFunction[] = [];
        functions.functions.forEach(f => {
            f.nodes.forEach(firstLevel => {
                firstLevel.nodes.forEach(func => functionList.push(func));
            });
        });

        matrixEntries.forEach(mEntry => {
            let func = functionList.find(f => f.node_id === mEntry.function_id);
            if (func !== undefined) {
                let withPoints = functionsWithPoints.find(x => x.node_id === func?.node_id);
                let connectedCategory = findCategory(store.getState().categories.categories, mEntry.category_id);

                if (withPoints === undefined) {
                    functionsWithPoints.push({ ...func, points: mEntry.value, connectedCategories: [connectedCategory] });
                }
                else {
                    withPoints.points += mEntry.value;
                    withPoints.connectedCategories.push(connectedCategory);
                }
            }
        })

        return functionsWithPoints;
    }
}

const instance = new MatrixService();

export { instance as MatrixService };
import axios, { AxiosInstance } from 'axios';

/**
 * A hook, that creates a new API client instance.
 *
 * @returns {AxiosInstance} The new instance.
 */
export function useApi(): AxiosInstance {

    return axios.create({
        baseURL: ['127.0.0.1', 'localhost'].includes(window.location.hostname?.toLowerCase().trim()) ?
            'http://localhost:8080/' :
            '/',
        headers: {
            'Accept-Language': String('en').toLowerCase().trim()
        }
    });
}

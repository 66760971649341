import React from 'react';

import styles from "./index.module.scss";

import ansprechpartnerImage from "../../assets/DSC_9367_2.jpg"
import iop from "../../assets/iop.png"

const HomePage: React.FC = () => {
    const renderMain = () => {
        return (
            <main className={styles.main}>
                <h1>IOP</h1>
                <p>
                    Das Internet of Production (IoP) ist eine an der RWTH Aachen entwickelte Vision, wie die Produktion ganzheitlich zu digitialisieren ist. Ziel ist die Steigerung der Wettbewerbsfähigkeit und Innovationskraft der produzierenden Industrie. Die Erforschung und Umsetzung in der Praxis des Internet of Production wird an der RWTH Aachen im Rahmen eines von der Bundesregierung geförderten Exzellenzclusters betrieben.
                </p>
                <p>
                    Im Rahmen des Internet of Production wird der Transfer des Internet of Things auf die Produktion und Produktionstechnik beschrieben. In modernen Fabriken fallen große Datenmengen an, die allerdings nur in Außnahmefällen umfassend genutzt werden. Die Transformation zu einem datengetriebenen Unternehmen und datenbasierten Entscheidungen ist dabei eine fundamentale Herausforderung, die neben einer modernen IT-Architektur auch Änderungen in der Organisation und Prozessen erfordert. Das Internet of Production ist dabei ein Referenzmodell, dass als Blaupause für eine moderne IT-Architektur dient.
                </p>
                <img src={iop} alt="IOP" style={{ margin: "20px 0" }} />
                <p>
                    Das Internet of Production struktruriert IT-Architekturen dabei in „Zyklen“ und „Schichten“. Es wird zwischen „Development Cycle“, „Production Cycle“ und „User Cycle“ unterschieden, die die drei zentralen Lebensphasen eines Produkts beschreiben. Die IT-Architektur wird in Anlehnung an existiertende Standards (ISO/OSI-Modell) in der Informatik in Schichten dargestellt. Dabei werden in der „Raw-Data“-Schicht die Expertensysteme und die darin gehaltenen Daten zusammengefasst, die heute in Unternehmen den Kern der IT-Architektur bilden. Darüber befindet sich die „Middleware+“-Schicht, die den umfassenden und intelligenten Zugriff auf die meist proprietären Systeme ermöglicht. Durch Vernetzung, Clusternung und lernende Algorithmen werden in der darauf aufbauenden Schicht „Smart Data“ Informationen und Wissen in Unternehmen generiert, die dann durch Menschen und Agenten (beispielsweise Künstliche Intelligenz oder Agentensysteme) für Entscheidungen genutzt werden können. Dies geschieht in der obersten Schicht, der „Smart Expert“-Schicht. Die Entscheidungen, die dort getroffen werden, werden dann über die „Middleware+“-Schicht wieder in die existierenden Systeme zurückgeführt.
                </p>
                <p>
                    Interdisziplinarität ist dabei ein zentrales Merkmal des Exzellenzclusters, in dem neben Informatiker:innen und Produktionstechniker:innen noch weitere Ingenieursdisziplinen und Sozialwissenschaftler:innen vertreten sind. Die Nutzung von Daten über Unternehmensgrenzen hinweg, sei es in der Entwicklung oder im B2C-Kontext ist dabei ein entscheidendes Merkmal für eine erfolgreiche digitale Transformation. Nur wer diese Daten erhebt und nutzt, kann datenbasierte Entscheidungen sinnvoll treffen. Gleichzeitig sind in diesem Kontext Fragestellungen wie digitale Souveränität, Vertrauen, und Datenhoheit von entscheidender Bedeutung.
                </p>
                <p>
                    Wenn Sie Interesse an einem Austausch zu modernen IT-Architekturen oder Fragestellungen des souveränen, unternehmensübergreifenden Austauschs haben., sprechen Sie uns gerne an!
                </p>
            </main>
        )
    }

    const renderAside = () => {
        return (
            <aside className={styles.aside}>
                <div className={styles.box}>
                    <h2>Kontaktieren Sie uns!</h2>
                    <div className={styles.spacer}></div>
                    <p>
                        Mathis Niederau<br />
                        Telefon: +49 241 47705-505<br />
                        <a href="mailto:mathis.niederau@fir.rwth-aachen.de">E-Mail</a>
                    </p>
                    <img src={ansprechpartnerImage} alt="Ansprechpartner" />
                </div>
            </aside>
        )
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        {renderMain()}
                    </div>
                    <div className="col-4">
                        {renderAside()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;

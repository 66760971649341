import { IMatrixState } from "../reducers/matrix";

export interface ISetMatrixData extends IMatrixState { }

export const SET_MATRIX = "SET_MATRIX";
export const RESET_MATRIX = "RESET_MATRIX";

export function setMatrix(matrix: ISetMatrixData) {
    return {
        type: SET_MATRIX,
        payload: {
            matrix
        }
    }
}

export function resetMatrix() {
    return {
        type: RESET_MATRIX,
        payload: {}
    }
}
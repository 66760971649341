import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import * as actions from "../../store/actions"
import { IReduxState } from '../../store';
import styles from "./index.module.scss";
import { IFunctionsState } from '../../store/reducers/functions';

function getObject(theObject: any, id: string): any {
    var result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject.length; i++) {
            result = getObject(theObject[i], id);
            if (result) {
                break;
            }
        }
    }
    else {
        for (var prop in theObject) {
            // console.log(prop + ': ' + theObject[prop]);
            if (prop === 'node_id') {
                if (theObject[prop] === id) {
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObject(theObject[prop], id);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

const FunctionOverviewPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const functions = useSelector((state: IReduxState) => state.functions);

    const handleGoBack = () => {
        history.push("/diagram")
    }

    const handleToggle = (node: any) => {
        let _functions = JSON.parse(JSON.stringify(functions));
        let _node = getObject(_functions, node.node_id)
        _node.active = !_node.active;
        dispatch(actions.setFunctions(_functions.functions));
    }

    const openAll = () => {
        let _functions: IFunctionsState = JSON.parse(JSON.stringify(functions));

        _functions.functions.forEach(func => {
            func.nodes.forEach(n1 => {
                n1.active = true;
                n1.nodes.forEach(n2 => {
                    n2.active = true;
                })
            })
        });

        dispatch(actions.setFunctions(_functions.functions as any));
    }

    const closeAll = () => {
        let _functions: IFunctionsState = JSON.parse(JSON.stringify(functions));

        _functions.functions.forEach(func => {
            func.nodes.forEach(n1 => {
                n1.active = false;
                n1.nodes.forEach(n2 => {
                    n2.active = false;
                })
            })
        });

        dispatch(actions.setFunctions(_functions.functions as any));
    }

    return (
        <div className={styles.container}>
            <div className={styles.actionContainer}>
                <button className={`btn btn-primary mx-1`} onClick={handleGoBack}>Zurück</button>
                <button className={`btn btn-secondary mx-1`} onClick={openAll}>Alles öffnen</button>
                <button className={`btn btn-secondary mx-1`} onClick={closeAll}>Alles schließen</button>
                <div className="flex-grow-1"></div>
            </div>

            <div className={styles.rootContainer}>
                {functions.functions.map(category => {
                    return (
                        <div className={styles.category} key={`category ${category.categorie_id}`}>
                            <div className={styles.categoryTitle}>
                                <strong>{category.name}</strong>
                            </div>
                            <div className={styles.firstLevelContainer}>
                                {category.nodes.map(firstLevel => {
                                    return (
                                        <div className={styles.firstLevel} key={`firstLevel ${firstLevel.node_id}`}>
                                            <div className={styles.firstLevelTitle} onClick={() => handleToggle(firstLevel)}>
                                                {firstLevel.name}
                                            </div>

                                            <div className={styles.secondLevelContainer}>
                                                {firstLevel.nodes.map(secondLevel => {
                                                    return (
                                                        <div className={`${styles.secondLevel} ${!firstLevel.active ? styles.hide : null}`} key={`secondLevel ${secondLevel.node_id}`}>
                                                            <div className={styles.secondLevelTitle} onClick={() => handleToggle(secondLevel)}>
                                                                {secondLevel.name}
                                                            </div>

                                                            <div className={styles.detailsLevelContainer}>
                                                                {secondLevel.details.map((detail, index) => {
                                                                    return (
                                                                        <div className={`${styles.details} ${!secondLevel.active ? styles.hide : null}`} key={`${secondLevel.node_id} details ${index}`}>
                                                                            {detail}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className={styles.actionContainer}>
                <button className={`btn btn-primary`} onClick={handleGoBack}>Zurück</button>
            </div>
        </div>
    );
};

export default FunctionOverviewPage;

import React from 'react';

import styles from "./index.module.scss"

import logo from "../../assets/app-logo.png"

interface IHeaderProps { }

const Header: React.FC<IHeaderProps> = (props) => {
    return (
        <header className={styles.header}>
            <div className="container" style={{ display: 'flex' }}>
                <a href="/">
                    <img className={styles.logo} src={logo} alt="logo" />
                </a>

                <div className="flex-grow-1"></div>

                <nav className={styles.nav}>
                    <a href="https://www.fir.rwth-aachen.de" target="_blank" rel="noreferrer">Das FIR</a>
                    <a href="https://www.fir.rwth-aachen.de/newsroom/" target="_blank" rel="noreferrer">Aktuelles</a>
                    <a href="https://www.fir.rwth-aachen.de/kontakt/" target="_blank" rel="noreferrer">Kontakt</a>
                </nav>
            </div>
        </header>
    )
}

export default Header;


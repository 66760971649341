import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';

import HomePage from './pages/Home';
import DemonstratorPage from './pages/Demonstrator';
import DiagramPage from './pages/Diagram';
import FunctionPage from './pages/FunctionOverview';
import OverviewPage from './pages/Overview';

function App() {
    return (
        <>
            <Header />
            <div style={{ margin: '0 auto', width: 1200, minHeight: 700 }}>
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/demonstrator" exact component={DemonstratorPage} />
                    <Route path="/diagram" exact component={DiagramPage} />
                    <Route path="/functions" exact component={FunctionPage} />
                    <Route path="/overview" exact component={OverviewPage} />


                    {/* Fallback */}
                    <Route path="/" component={HomePage} />
                </Switch>
            </div>
            <Footer />
        </>
    );
}

export default App;

import { IFunctionsState } from "../reducers/functions";

export interface ISetFunctionsData extends IFunctionsState { }

export const SET_FUNCTIONS = "SET_FUNCTIONS";
export const RESET_FUNCTIONS = "RESET_FUNCTIONS";

export function setFunctions(functions: ISetFunctionsData) {
    return {
        type: SET_FUNCTIONS,
        payload: {
            functions
        }
    }
}

export function resetFunctions() {
    return {
        type: RESET_FUNCTIONS,
        payload: {}
    }
}
import produce from "immer"
import * as actions from "../actions";
import logicJson from "../../logic.json";

export interface IFunctionsState {
    functions: IFunction[]
}

export interface IFunction {
    categorie_id: string;
    name: string;
    nodes: IFirstLevel[];
}

export interface IFirstLevel {
    node_id: string;
    name: string;
    active: boolean;
    nodes: ISecondLevel[];
}

export interface ISecondLevel {
    node_id: string;
    name: string;
    active: boolean;
    details: string[];
    iopRow: number;
    relatedIopRows: number[];
    desc: string;
}

const initialState: IFunctionsState = {
    functions: logicJson.functions
}

export default function storeReducer(state: IFunctionsState = initialState, action: any) {
    return produce(state, draftState => {
        switch (action.type) {
            case actions.SET_FUNCTIONS:
                draftState.functions = action.payload.functions;
                break;
            case actions.RESET_FUNCTIONS:
                draftState = initialState;
                break;
        }
    })
}
import produce from "immer"
import * as actions from "../actions";
import categoriesJson from "../../logic.json";

export interface ICategoriesState {
    categories: ICategory[]
}

export interface ICategory {
    categorie_id: string;
    name: string;
    nodes: IFirstLevel[];
}

interface IFirstLevel {
    node_id: string;
    name: string;
    active: boolean;
    nodes: ISecondLevel[];
}

interface ISecondLevel {
    node_id: string;
    name: string;
    active: boolean;
    nodes: IThirdLevel[];
}

interface IThirdLevel {
    node_id: string;
    name: string;
    active: boolean;
}

const initialState: ICategoriesState = {
    categories: categoriesJson.categories
}

export default function storeReducer(state: ICategoriesState = initialState, action: any) {
    return produce(state, draftState => {
        switch (action.type) {
            case actions.SET_CATEGORIES:
                draftState.categories = action.payload.categories;
                break;
            case actions.RESET_CATEGORIES:
                draftState = initialState;
                break;
        }
    })
}
import produce from "immer"
import * as actions from "../actions";
import logicJson from "../../logic.json";

export interface IMatrixState {
    matrix: IMatrixEntry[]
}

export interface IMatrixEntry {
    category_id: string;
    function_id: string;
    value: number;
}

const initialState: IMatrixState = {
    matrix: logicJson.matrix
}

export default function storeReducer(state: IMatrixState = initialState, action: any) {
    return produce(state, draftState => {
        switch (action.type) {
            case actions.SET_MATRIX:
                draftState.matrix = action.payload.matrix;
                break;
            case actions.RESET_MATRIX:
                draftState = initialState;
                break;
        }
    })
}
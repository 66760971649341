import React from 'react';
import { useHistory, Link } from "react-router-dom";

import styles from "./index.module.scss";

import ansprechpartnerImage from "../../assets/DSC_9367_2.jpg"

const HomePage: React.FC = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push("/demonstrator")
    }

    const handleGoOverview = () => {
        history.push("/overview")
    }

    const renderMain = () => {
        return (
            <main className={styles.main}>
                <h1>EIS4IoP</h1>
                <h2>Energieinformationssysteme im Internet of Production</h2>
                <h3>Ziel</h3>
                <p>
                    Ziel des Projekts „EIS4IoP – Energieinformationssysteme im <Link to="overview" onClick={handleGoOverview}>Internet of Production</Link>“ ist es, Unternehmen bei der Auswahl und Einführung von Energieinformationsystemen zu unterstützen. Energiemanagement und Nachhaltigkeitsmanagement sind von zunehmender Bedeutung für Unternehmen. Energieinformationssysteme stellen dafür die IT-seitige Basis dar. Die Aufwände für die Auswahl und Einführung von Energieinformationsysteme stehen insbesondere für KMU in keinem ausgewogenen Verhältnis mit den erzielten finanziellen Einsparungen.
                </p>
                <p>
                    Vor diesem Hintergrund wurde während des Projekts dieser Demonstrator entwickelt, der Sie auf Basis Ihrer unternehmensindividuellen Prioritäten dabei unterstützt Handlungsfelder im Bereich des Energiemanagements zu identifizieren. Die intuitive Bedienung des Tools hilft bei der Strukturierung und bildet die Basis für eine detaillierte Anforderungserhebung auf Basis der im Tool integrierten Wirkungszusammenhängen zwischen Unternehmenszielen und benötigten Funktionen.
                </p>
                <h3>So geht's</h3>
                <p>
                    Im Folgenden werden Sie zunächst eine Auswahl an Zielen treffen, welche Sie im Kontext des Energiemanagements bzw. des Nachhaltigkeitsmanagements als besonders relevant erachten und deren Erreichung Sie für Ihr Unternehmen priorisieren. Dabei haben Sie die Möglichkeit aus den Dimensionen Ökonomisch, Ökologisch und Sozial Ziele auszuwählen.
                </p>
                <p>
                    Dieses Tool präsentiert Ihnen darauf basierend eine Funktionsauswahl, deren Integration Ihrem Unternehmen die Erreichung der gewählten Energiemanagementziele ermöglicht. Diese Auswahl wird in der an der RWTH Aachen entwickelten Referenzarchitektur <Link to="overview" onClick={handleGoOverview}>Internet of Production</Link> verortet und in Listenform, in der die Funktionen in absteigender Priorität sortiert werden. Die Referenzarchitektur unterstützt bei der Implementierung. Bei Interesse können Sie sich die Ergebnisse des Demonstrators per Mail zusenden lassen, um auf Basis der Informationen eine vertiefte Recherche von Energieinformationssystemen durchzuführen und eine anschließende Auswahl zu treffen.
                </p>
                <h3>Alles klar! Los geht's!</h3>
                <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#infoModal">Ziele definieren</div>
            </main >
        )
    }

    const renderAside = () => {
        return (
            <aside className={styles.aside}>
                <div className={styles.box}>
                    <h3>Ansprechpartner</h3>
                    <div className={styles.spacer}></div>
                    <p>
                        Mathis Niederau<br />
                        Telefon: +49 241 47705-505<br />
                        <a href="mailto:mathis.niederau@fir.rwth-aachen.de">E-Mail</a>
                    </p>
                    <img src={ansprechpartnerImage} alt="Ansprechpartner" />
                </div>
                <div className={styles.box}>
                    <h3>Projektinformationen</h3>
                    <div className={styles.spacer}></div>
                    <p>
                        <strong>Förderkennzeichen</strong>
                        <span>IGF-Vorhaben Nr. 20945N</span>
                    </p>
                    <p>
                        <a href="http://eis4iop.fir.de/" target="_blank" rel="noreferrer">Zur Projektbeschreibung</a>
                    </p>
                </div>
            </aside>
        )
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        {renderMain()}
                    </div>
                    <div className="col-4">
                        {renderAside()}
                    </div>
                </div>
            </div>

            <div className="modal fade" id="infoModal" aria-labelledby="infoModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "1100px" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoModalLabel">Bevor Sie loslegen… </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Das Demonstratortool, das im Forschungsprojekt EIS4IOP entwickelt wurde, dient dazu, Unternehmen, insbesondere KMU, bei der Auswahl von Energiemanagementfunktionen zu unterstützen.
                            </p>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className={styles.blueInfoBox}>
                                            <div className={styles.content}>
                                                Sie wählen ökonomische Ziele, ökologische Ziele und/oder soziale Ziele aus.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={styles.greyArrow}>
                                            <svg viewBox="0 0 302 260" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 66V189.333H171.333V260L302 125.333L171.333 0V66H0Z" fill="#9C9E9F" />
                                            </svg>
                                            <div className={styles.content}>
                                                Der Demonstrator übersetzt Ihre Zielauswahl in notwendige Funktionen.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={styles.blueInfoBox}>
                                            <div className={styles.content}>
                                                Sie erhalten eine Priorisierung der notwendigen Funktionen, um die ausgewählten Ziele zu erreichen.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClick}>Jetzt starten</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { IReduxState } from '../../store';
import * as actions from "../../store/actions"
import styles from "./index.module.scss";

function getObject(theObject: any, id: string): any {
    var result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject.length; i++) {
            result = getObject(theObject[i], id);
            if (result) {
                break;
            }
        }
    }
    else {
        for (var prop in theObject) {
            // console.log(prop + ': ' + theObject[prop]);
            if (prop === 'node_id') {
                if (theObject[prop] === id) {
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObject(theObject[prop], id);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

const DemonstratorPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [categoryCounter0, setCategoryCounter0] = useState(0);
    const [categoryCounter1, setCategoryCounter1] = useState(0);
    const [categoryCounter2, setCategoryCounter2] = useState(0);
    const [activeCategory, setActiveCategory] = useState(undefined as number | undefined)
    const categories = useSelector((state: IReduxState) => state.categories);
    const [alertModal, setAlertModal] = useState(null as any);

    useEffect(() => {
        // @ts-ignore
        setAlertModal(new bootstrap.Modal(document.getElementById('alertModal'), {
            keyboard: false
        }))
    }, [])
    useEffect(() => {
        let counter = 0;
        let _category = categories.categories[activeCategory ?? 0];

        _category.nodes.forEach(firstLevel => {
            firstLevel.nodes.forEach(secondLevel => {
                let activeNodes = secondLevel.nodes.filter(thirdLevel => thirdLevel.active)
                counter += activeNodes.length;
            })
        })

        if (activeCategory === 0) setCategoryCounter0(counter);
        if (activeCategory === 1) setCategoryCounter1(counter);
        if (activeCategory === 2) setCategoryCounter2(counter);

        if (counter >= 7) {
            toggleAlertModal();
        }
    }, [categories])

    const toggleAlertModal = () => {
        alertModal.toggle();
    }

    const handleCategoryChange = (cat: number) => {
        setActiveCategory(cat)
    }

    const handleActiveToggle = (node: any) => {
        let _categories = JSON.parse(JSON.stringify(categories));
        let _cat = getObject(_categories, node.node_id);
        _cat.active = !_cat.active;
        dispatch(actions.setCategories(_categories.categories));
    }

    const handleShowFunctions = () => {
        history.push("/diagram")
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.rootContainer}>
                    <div className={`${styles.rootNode} ${styles.blue} ${activeCategory === 0 ? styles.active : ''}`} onClick={() => handleCategoryChange(0)}>
                        <span>Ökonomisch</span>
                        <span className={styles.counter}>{categoryCounter0}</span>
                    </div>
                    <div className={`${styles.rootNode} ${styles.green} ${activeCategory === 1 ? styles.active : ''}`} onClick={() => handleCategoryChange(1)}>
                        <span>Ökologisch</span>
                        <span className={styles.counter}>{categoryCounter1}</span>
                    </div>
                    <div className={`${styles.rootNode} ${styles.purple} ${activeCategory === 2 ? styles.active : ''}`} onClick={() => handleCategoryChange(2)}>
                        <span>Sozial</span>
                        <span className={styles.counter}>{categoryCounter2}</span>
                    </div>

                    <button className={`btn btn-primary ${styles.btn}`} onClick={handleShowFunctions} disabled={categoryCounter0 + categoryCounter1 + categoryCounter2 < 1}>Funktionen anzeigen</button>
                </div>

                {activeCategory === undefined &&
                    <h2 className="text-center mt-5" style={{ width: '500px', margin: '0 auto' }}>Bitte wählen Sie aus, mit welchem Bereich Sie starten wollen</h2>
                }

                {activeCategory !== undefined &&
                    <div className={styles.treeView}>
                        <div className={`${styles.firstLevelLineWrapper} ${styles['col-' + categories.categories[activeCategory].nodes.length]}`}>
                            {Array.apply(null, new Array(categories.categories[activeCategory].nodes.length - 1)).map((_, index) => {
                                return <div key={`firstLevelLine ${index}`} className={styles.firstLevelLine}></div>
                            })}
                        </div>
                        <div className={styles.firstLevel}>
                            {categories.categories[activeCategory].nodes && categories.categories[activeCategory].nodes.map((firstLevel: any) => {
                                return (
                                    <div className={styles.firstLevelInner} key={firstLevel.node_id}>
                                        <div className={`${styles.treeNode} ${firstLevel.active && styles.active} ${activeCategory === 0 && styles.blue} ${activeCategory === 1 && styles.green} ${activeCategory === 2 && styles.purple}`} onClick={() => { handleActiveToggle(firstLevel) }}>
                                            {firstLevel.name}
                                        </div>
                                        {firstLevel.active && <div className={styles.secondLevelLine}></div>}
                                        <div className={styles.secondLevel}>
                                            {firstLevel.active && firstLevel.nodes && firstLevel.nodes.map((secondLevel: any) => {
                                                return (
                                                    <div className={styles.secondLevelInner} key={secondLevel.node_id}>
                                                        <div className={`${styles.treeNode} ${secondLevel.active && styles.active} ${activeCategory === 0 && styles.blue} ${activeCategory === 1 && styles.green} ${activeCategory === 2 && styles.purple}`} onClick={() => { handleActiveToggle(secondLevel) }}>
                                                            {secondLevel.name}
                                                        </div>

                                                        <div className={styles.thirdLevel}>
                                                            {secondLevel.active && secondLevel.nodes && secondLevel.nodes.map((thirdLevel: any) => {
                                                                return (
                                                                    <div
                                                                        className={`${styles.treeNode} ${thirdLevel.active ? styles.active : ''} ${activeCategory === 0 && styles.blue} ${activeCategory === 1 && styles.green} ${activeCategory === 2 && styles.purple}`}
                                                                        key={thirdLevel.node_id}
                                                                        onClick={() => { handleActiveToggle(thirdLevel) }}
                                                                    >
                                                                        {thirdLevel.name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>

            <div className="modal fade" id="alertModal" aria-labelledby="alertModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="alertModalLabel">Super, dass Sie so ambitioniert sind!</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={toggleAlertModal}></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Um eine gute Basis zu schaffen, beschränken Sie Ihre Auswahl bitte auf maximal 7 Ziele, damit Sie mit dem Ergebnis auch wirklich weiter arbeiten können.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="btn btn-primary" onClick={toggleAlertModal}>Alles klar!</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DemonstratorPage;

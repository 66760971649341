import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import categories, { ICategoriesState } from "./reducers/categories";
import functions, { IFunctionsState } from "./reducers/functions";
import matrix, { IMatrixState } from "./reducers/matrix";

export interface IReduxState {
    categories: ICategoriesState
    functions: IFunctionsState;
    matrix: IMatrixState;
}

const rootReducer = combineReducers({
    categories,
    functions,
    matrix
})

const persistConfig: PersistConfig<IReduxState> = {
    key: 'fir',
    storage,
    whitelist: [
        
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { IFunctionWithPoints } from "../../services/matrixService"
import { IReduxState } from '../../store';
import styles from "./index.module.scss";

import IOP from "../../components/iop";
import { MatrixService } from "../../services/matrixService";
import { useApi } from '../../api';


const DiagramPage: React.FC = () => {
    const api = useApi();
    const history = useHistory();
    const categories = useSelector((state: IReduxState) => state.categories);
    const [functionWithPoints, setFunctionWithPoints] = useState([] as IFunctionWithPoints[])
    const [salutation, setSalutation] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [loadingPdfExport, setLoadingPdfExport] = useState(false);
    const [exportModal, setExportModal] = useState(null as any);
    const [successModal, setSuccessModal] = useState(null as any);

    useEffect(() => {
        setFunctionWithPoints(MatrixService.calcFunctionValues());

        // @ts-ignore
        setExportModal(new bootstrap.Modal(document.getElementById('exportModal'), {
            keyboard: false
        }))

        // @ts-ignore
        setSuccessModal(new bootstrap.Modal(document.getElementById('successModal'), {
            keyboard: false
        }))
    }, [])

    const countCategory = (categoryIndex: number) => {
        let counter = 0;
        let _category = categories.categories[categoryIndex];

        _category.nodes.forEach(firstLevel => {
            firstLevel.nodes.forEach(secondLevel => {
                let activeNodes = secondLevel.nodes.filter(thirdLevel => thirdLevel.active)
                counter += activeNodes.length;
            })
        })

        return counter;
    }

    const handleGoBack = () => {
        history.push("/demonstrator")
    }

    const handleGoFunctions = () => {
        history.push("/functions")
    }

    const handleGoOverview = () => {
        history.push("/overview")
    }

    const renderSvg = () => {
        return (
            <div className={styles.iopContainer} id="diagram">
                <IOP functions={functionWithPoints} activeHoverEffect />
            </div>
        )
    }

    const renderList = () => {
        const pointLimitHight = Math.floor(functionWithPoints.length / 4);
        const pointLimitMiddle = Math.floor(functionWithPoints.length / 2);

        return (
            <div className={styles.tableContainer}>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Funktion</th>
                            <th>Beschreibung</th>
                            <th>Impact</th>
                        </tr>
                    </thead>
                    <tbody>
                        {functionWithPoints.length > 0 && functionWithPoints.map((func, index) => {
                            return (
                                <tr key={`row-${index}`}>
                                    <td>{func.name}</td>
                                    <td>{func.desc}</td>
                                    <td>{index < pointLimitHight ? 'Hoch' : index < pointLimitMiddle ? 'Mittel' : 'Niedrig'}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    const exportAsEmail = async (e: any) => {
        e.preventDefault();

        const pointLimitHight = Math.floor(functionWithPoints.length / 4);
        const pointLimitMiddle = Math.floor(functionWithPoints.length / 2);

        const diagramContainer = document.getElementById("diagram");
        const svgString = diagramContainer?.innerHTML;

        setLoadingPdfExport(true);

        api.post("/mails/pdf",
            {
                salutation,
                first_name: firstName,
                last_name: lastName,
                email,
                svg: svgString,
                goals: MatrixService.getSelectedCategoriesAsArray(),
                functions: functionWithPoints.map((func, index) => {
                    return {
                        name: func.name,
                        desc: func.desc,
                        impact: index < pointLimitHight ? 'Hoch' : index < pointLimitMiddle ? 'Mittel' : 'Niedrig'
                    }
                })
            })
            .then(response => {
                console.log(response);
                setLoadingPdfExport(false);
                exportModal.toggle();
                successModal.toggle();
            })
            .catch(err => {
                console.error(err);
                setLoadingPdfExport(false);
            })
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.rootContainer}>
                    <div className={`${styles.rootNode} ${styles.blue}`} onClick={handleGoBack}>
                        <span>Ökonomisch</span>
                        <span className={styles.counter}>{countCategory(0)}</span>
                    </div>
                    <div className={`${styles.rootNode} ${styles.green}`} onClick={handleGoBack}>
                        <span>Ökologisch</span>
                        <span className={styles.counter}>{countCategory(1)}</span>
                    </div>
                    <div className={`${styles.rootNode} ${styles.purple}`} onClick={handleGoBack}>
                        <span>Sozial</span>
                        <span className={styles.counter}>{countCategory(2)}</span>
                    </div>
                </div>

                {renderSvg()}

                {renderList()}

                <div className={styles.actionContainer}>
                    <button className={`btn btn-primary`} onClick={handleGoFunctions}>Funktionsübersicht</button>
                    <button className={`btn btn-link`} data-bs-toggle="modal" data-bs-target="#exportModal">Ergebnis per Mail erhalten</button>
                    <button className={`btn btn-secondary`} onClick={handleGoOverview}>Übersicht IOP</button>
                </div>
            </div>

            <div className="modal fade" id="exportModal" aria-labelledby="exportModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <form onSubmit={exportAsEmail}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exportModalLabel">Ergebnisse per Mail erhalten</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioFrau" value="Frau" required onChange={e => setSalutation(e.target.value)} />
                                        <label className="form-check-label" htmlFor="radioFrau">Frau</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioHerr" value="Herr" onChange={e => setSalutation(e.target.value)} />
                                        <label className="form-check-label" htmlFor="radioHerr">Herr</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioDivers" value="Divers" onChange={e => setSalutation(e.target.value)} />
                                        <label className="form-check-label" htmlFor="radioDivers">Divers</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputVorname" className="form-label">Vorname</label>
                                    <input type="text" className="form-control" id="inputVorname" required onChange={e => setFirstName(e.target.value)} />
                                    <label htmlFor="inputNachname" className="form-label">Nachname</label>
                                    <input type="text" className="form-control" id="inputNachname" required onChange={e => setLastName(e.target.value)} />
                                    <label htmlFor="inputEmail" className="form-label">E-Mail</label>
                                    <input type="email" className="form-control" id="inputEmail" required onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div>
                                    Ihre Angaben werden ausschließlich zum Versenden der E-Mail verwendet und nicht weiter verarbeitet
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="submit" className="btn btn-primary" disabled={loadingPdfExport}>
                                    {!loadingPdfExport && <span>Ergebnisse zusenden</span>}
                                    {loadingPdfExport && <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade" id="successModal" aria-labelledby="successModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <form onSubmit={exportAsEmail}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="successModalLabel">Das hat geklappt!</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Eine Mail mit Ihrem Ergebnis ist auf dem Weg zu Ihnen.
                                Vielen Dank, dass Sie unser Tool nutzen.
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                                    Alles klar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DiagramPage;

import React from 'react';

import styles from "./index.module.scss"

import logo from "../../assets/fir-logo.png"

interface IFooterProps { }

const Footer: React.FC<IFooterProps> = (props) => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>Kontakt</h3>

                        <a href="mailto:projekt-eis4iop@fir.rwth-aachen.de">projekt-eis4iop@fir.rwth-aachen.de</a>
                        <br />
                        <a href="https://www.fir.rwth-aachen.de/impressum/" target="_blank" rel="noreferrer">Impressum</a>
                        <span> | </span>
                        <a href="https://www.fir.rwth-aachen.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a>
                    </div>
                    <div className="col">
                        <div className="widget-area">
                            <img width="85" height="47" src={logo} alt="" style={{ maxWidth: '100%', marginBottom: 20 }} />

                            <div style={{ marginBottom: 20 }}>
                                FIR e. V. an der RWTH Aachen<br />
                                Campus-Boulevard 55<br />
                                52074 Aachen
                            </div>

                            <a href="https://www.fir.rwth-aachen.de" target="_blank" rel="noopener noreferrer">www.fir.rwth-aachen.de</a>

                            <div className={styles.social}>
                                <a href="http://twitter.fir.de" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                <a href="http://facebook.fir.de" target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a>
                                <a href="http://xing.fir.de" target="_blank" rel="noreferrer"><i className="fab fa-xing"></i></a>
                                <a href="http://linkedin.fir.de" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a>
                                <a href="http://fir-mediathek.de" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                                <a href="http://instagram.fir.de" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                            </div>

                            <a href="http://newsletter-anmeldung-pswp.fir.de" target="_blank" rel="noopener noreferrer">FIR-Newsletter-Abo</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;


import { ICategoriesState } from "../reducers/categories";

export interface ISetCategoriesData extends ICategoriesState { }

export const SET_CATEGORIES = "SET_CATEGORIES";
export const RESET_CATEGORIES = "RESET_CATEGORIES";

export function setCategories(categories: ISetCategoriesData) {
    return {
        type: SET_CATEGORIES,
        payload: {
            categories
        }
    }
}

export function resetCategories() {
    return {
        type: RESET_CATEGORIES,
        payload: {}
    }
}